
import Cookies from 'universal-cookie';
const cookies = new Cookies(); 

let ListPag = {
    "page"            : 'login',
    "currentPage"     : 'index',
    "currentRegister" : '',
    "content_type"    : '',
    "idPage"          : '',
    "type"            : ''
};

let NotifyListPag = {
    "page"            : [],
    "currentPage"     : [],
    "currentRegister" : [],
    "content_type"    : [],
    "idPage"          : []
}

export function GetListPag(key){
    return ListPag[key];
}
export function SetListPag(key, value){
    ListPag[key] = value;
    NotifyListPag[key].forEach(element => {
        element(value);
    });

    if(key == 'currentPage'){
        cookies.set('metazonia_page', value, '/', 'dashmetazonia.chroma-garden.com');
    }

    if(key == 'currentRegister'){
        cookies.set('metazonia_register', value, '/', 'dashmetazonia.chroma-garden.com');
    }
}
export function RegisterListPag(key, value){
    if(!NotifyListPag[key]){
        NotifyListPag[key] = []; 
    }
    NotifyListPag[key].push(value);
}