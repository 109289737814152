
import React, { useState, useEffect } from "react";

import Login from "templates/Login";

import { SetListPag, GetListPag, RegisterListPag } from "interface/InterfacePag";

import Cookies from 'universal-cookie';
import Access from "services/AccessLogin";
import Contents from "templates/Contents";
import ListData from "components/PopUp/ListData";
import Crop from "components/PopUp/Crop";
import Confirmation from "components/PopUp/Confirmation";
import ShowFile from "components/PopUp/ShowFile";
import NewMenu from "components/PopUp/NewMenu";
import SavedData from "components/PopUp/SavedData";

function App() {

    const cookies = new Cookies();  
    const [ currentPage, setCurrentPage ] = useState(GetListPag('page'));

    useEffect(()=>{
        RegisterListPag('page', setCurrentPage);
        if(cookies.get('metazonia')){    
            let inf_cookies = cookies.get('metazonia').split(';');  
            Access(inf_cookies[0], inf_cookies[1], callback_success, ()=>{});
        }
    }, []);

    // Checked cookies
    function callback_success(){ 
        if(cookies.get('metazonia_page')){  
            let cookiesPages = cookies.get('metazonia_page'); 
            let cookiesPages_ = cookies.get('metazonia_register'); 
            SetListPag('currentPage', cookiesPages); 
            SetListPag('currentRegister', cookiesPages_); 
            
        }else {
            cookies.set('metazonia_page', 'index', '/', 'dashmetazonia.chroma-garden.com');
            let cookiesPages = cookies.get('metazonia_page');
            SetListPag('currentPage', cookiesPages); 
            
            let cookiesPages_ = cookies.get('metazonia_register'); 
            SetListPag('currentRegister', cookiesPages_); 
        }
    }

    // checked page
    function checkedPage(namePage){
        switch(namePage){
            case "index":
                return(
                    <Contents />
                ); 
            default:
                return(
                    <Login />
                ); 
        }
    }

    return (
        <>
            { checkedPage(currentPage) }
            <Crop />
            <ListData />
            <Confirmation />
            <ShowFile />
            <SavedData />
            <NewMenu />
        </>
    );
}

export default App;
