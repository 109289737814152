import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import Axios from 'axios';

import { SetListData } from 'interface/InterfaceData';

const root = ReactDOM.createRoot(document.getElementById('root'));

Axios('https://dashmetazonia.chroma-garden.com/php/register.php?acao=list_data') 
.then(response => {
    const rootComponent = document.querySelector(':root');
    rootComponent.style.setProperty('--menu_background', response.data.customization.menu_background);
    rootComponent.style.setProperty('--menu_text', response.data.customization.menu_text);
    rootComponent.style.setProperty('--menu_active', response.data.customization.menu_active);
    rootComponent.style.setProperty('--topic_background', response.data.customization.topic_background);
    rootComponent.style.setProperty('--topic_text', response.data.customization.topic_text);

    SetListData(response.data);
    root.render(
        <React.Fragment>
            <App />
        </React.Fragment>
    ); 
})