
import { useState, useEffect } from "react";

import './Flickr.css';

import { GetListPag, RegisterListPag } from "interface/InterfacePag";
import { GetCustomization, RegisterCustomization } from "interface/InterfaceData";

export default function Flickr(props){

    const [ pageClicked, setPageClicked ] = useState(GetListPag('currentPage'));
    const [ pageState, setPageState ]     = useState(GetListPag('currentRegister'));
    const [ listMenu, setListMenu ]       = useState(GetCustomization('menu'));

    const [ title, setTitle ]             = useState('');
    const [ link, setLink ]               = useState('');

    useEffect(()=>{
        RegisterListPag('currentPage', setPageClicked);
        RegisterListPag('currentRegister', setPageState);

        RegisterCustomization('menu', setListMenu);
    }, []); 

    useEffect(()=>{
        if(pageState.idMenu !=0){
            initialLink();
            initialTitle();
        }
    }, [pageState]); 

    function initialTitle(){  
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setTitle(data_menu.title);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                setTitle(data_submenu.title);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setTitle(data_submenusub.title);
        }   
    }
    function initialLink(){  
        const dataMenu       = listMenu.find(item => item.id == pageState.idMenu);
        const dataSubMenu    = dataMenu.submenu.find(item => item.id == pageState.idSubMenu);
        const dataSubMenuSub = dataSubMenu.submenu.find(item => item.id == pageState.idSubMenu_sub);

        switch(props.type){
            case "menu":
                const data_menu = dataMenu.contents.find(item => item.id == props.id);
                setLink(data_menu.link);
                
            case "submenu":
                const data_submenu = dataSubMenu.contents.find(item => item.id == props.id);
                console.log(data_submenu);
                setLink(data_submenu.link);
                
            case "submenusub":
                const data_submenusub = dataSubMenuSub.contents.find(item => item.id == props.id);
                setLink(data_submenusub.link);
        }   
    }

    function handleTitle(value){
        setTitle(value); 
        props.RegisterData('flickr', { "id" : props.id, "title" : value, "link" : link, "index" : props.index }, props.index);
    }

    function handleLink(value){
        setLink(value); 
        props.RegisterData('flickr', { "id" : props.id, "title" : title, "link" : value, "index" : props.index }, props.index);
    }

    return(
        <div className="Flickr">
            <div className="div_input">
                <div>
                    Texto do botão
                </div>
                <div>
                    <input type="text" onChange={ (e)=>{ handleTitle(e.target.value) } } value={ title } />
                </div>
                <div>
                    Link
                </div>
                <div className="space_input">
                    <input type="text" onChange={ (e)=>{ handleLink(e.target.value) } } value={ link } />
                </div>
            </div>
        </div>
    )
}